<template>
  <div>
    <form-sign-in
      inside-dialog
      :disable-modal="disableModal"
      @submit="handleSubmit"
      @forgot-password="emit('forgotPassword')"
    />
    <div v-if="$feature.allowNewSignUp" class="mt-6 items-center gap-2 text-sm ">
      <base-interpolator :content="replaceAll($t.notAMember, { brandName: capitalize(brand) })">
        <template #createAccount>
          <vf-link class="ml-2" @click="openModalSignUp">
            {{ $t.createAccount }}
          </vf-link>
        </template>
      </base-interpolator>
    </div>
    <vf-button
      v-if="$feature.showSeparateSignupCtaInSigninModal"
      type="submit"
      class="mt-4 <md:full"
      variant="tertiary"
      @click="openModalSignUp"
    >
      {{ $t.joinLoyaltyCta }}
    </vf-button>
  </div>
</template>

<script lang="ts" setup>
export type Props = { disableModal?: boolean }
export type Emits = { resolve: [submitted?: boolean], signUp: [], forgotPassword: [] }
const props = defineProps<Props>()
const emit = defineEmits<Emits>()
const { $gtm } = useNuxtApp()
const { getSections } = useCms()
const { ModalSignUp } = useDialogsStore()
const { brand } = useRuntimeConfig().public
const { query } = useRoute()
const isOnOrderDetails = !!(query.orderNumber || query.email || query.zipCode)
let enableGtmClose = true

const handleSubmit = () => {
  enableGtmClose = false
  if (isOnOrderDetails) navigateTo('/account')
  emit('resolve', true)
}
const openModalSignUp = async () => {
  enableGtmClose = false
  emit('signUp')
  if (!props.disableModal) {
    const eSpots = (await getSections.signUpModal()).data.value?.content.sectionsMap
    ModalSignUp.open(
      { eSpot: eSpots?.['below-sign-up'] },
      { size: eSpots?.['below-sign-up'] ? 'md' : 'xs' }
    )
  }
}

onBeforeUnmount(() => {
  if (enableGtmClose) $gtm.push('authForm.onLoginClose', 'modal:single:none')
})
</script>
